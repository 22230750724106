<template>
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="10.9089"
      width="15.4278"
      height="1.54278"
      rx="0.771389"
      transform="rotate(-45 0 10.9089)"
      fill="#061237"
    />
    <rect
      width="15.4278"
      height="1.54278"
      rx="0.771389"
      transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 12 10.9089)"
      fill="#061237"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-times",
};
</script>
<style scoped lang="scss"></style>
