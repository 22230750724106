<template>
  <svg
    width="20"
    height="28"
    viewBox="0 0 20 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 6.99993H18.5172C18.5567 6.99993 18.5954 6.9882 18.6283 6.96623C18.6612 6.94425 18.6868 6.91301 18.7019 6.87647C18.7171 6.83992 18.721 6.7997 18.7133 6.76091C18.7056 6.72211 18.6865 6.68647 18.6586 6.6585L13.3414 1.3413C13.3135 1.31333 13.2778 1.29428 13.239 1.28656C13.2002 1.27884 13.16 1.2828 13.1235 1.29794C13.0869 1.31308 13.0557 1.33871 13.0337 1.3716C13.0117 1.40449 13 1.44316 13 1.48272V5.99993C13 6.26515 13.1054 6.5195 13.2929 6.70704C13.4804 6.89458 13.7348 6.99993 14 6.99993Z"
      fill="#767E81"
    />
    <path
      d="M19 9H14C13.2044 9 12.4413 8.68393 11.8787 8.12132C11.3161 7.55871 11 6.79565 11 6V1.00256C11.0004 0.871456 10.9749 0.741562 10.9251 0.620314C10.8752 0.499065 10.8019 0.388842 10.7094 0.295956C10.6168 0.20307 10.5069 0.129344 10.3859 0.0789995C10.2648 0.0286545 10.135 0.00267897 10.0039 0.0025599C7.66156 0.0013399 2 0 2 0C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V26C0 26.5304 0.210714 27.0391 0.585786 27.4142C0.960859 27.7893 1.46957 28 2 28H18C18.5304 28 19.0391 27.7893 19.4142 27.4142C19.7893 27.0391 20 26.5304 20 26V10C20 9.73478 19.8946 9.48043 19.7071 9.29289C19.5196 9.10536 19.2652 9 19 9Z"
      fill="#767E81"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-file",
};
</script>
<style scoped lang="scss"></style>
