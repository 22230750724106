<template>
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.59404 9.4828C4.47949 9.59802 4.32321 9.6623 4.16087 9.6623C3.99853 9.6623 3.84225 9.59802 3.7277 9.4828L0.268282 6.02282C-0.0907296 5.66381 -0.0907296 5.08165 0.268282 4.72331L0.701453 4.29002C1.06058 3.93101 1.64206 3.93101 2.00108 4.29002L4.16087 6.44993L9.99695 0.613741C10.3561 0.254729 10.9381 0.254729 11.2966 0.613741L11.7297 1.04702C12.0888 1.40604 12.0888 1.98808 11.7297 2.34654L4.59404 9.4828Z"
      fill="#1355FF"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-approve",
};
</script>
<style scoped lang="scss"></style>
