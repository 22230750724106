<template>
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.4086 6.06282C16.1486 2.75497 13.3736 0.142822 10 0.142822C7.11429 0.142822 4.63714 2.01711 3.83786 4.75068C1.55786 5.54211 0 7.70211 0 10.1428C0 13.2935 2.56357 15.8571 5.71429 15.8571H15C17.7571 15.8571 20 13.6143 20 10.8571C20 8.60782 18.5214 6.67711 16.4086 6.06282ZM15 14.4285H5.71429C3.35071 14.4285 1.42857 12.5064 1.42857 10.1428C1.42857 8.20568 2.73786 6.50425 4.61214 6.00568C4.86929 5.93711 5.06714 5.73139 5.12571 5.47211C5.63286 3.21211 7.68286 1.57139 10 1.57139C12.6386 1.57139 14.9971 3.65997 14.9971 6.63496C14.9971 6.98425 15.2493 7.28211 15.5936 7.33925C17.3193 7.62854 18.5714 9.10782 18.5714 10.8571C18.5714 12.8264 16.9693 14.4285 15 14.4285Z"
      fill="white"
    />
    <path
      d="M13.3612 7.4952C13.6404 7.77449 13.6404 8.22591 13.3612 8.5052C13.0819 8.78449 12.6304 8.78449 12.3512 8.5052L10.7133 6.86734V12.2859C10.7133 12.6802 10.3933 13.0002 9.99902 13.0002C9.60473 13.0002 9.28473 12.6802 9.28473 12.2859V6.86734L7.64687 8.5052C7.36759 8.78449 6.91616 8.78449 6.63687 8.5052C6.35759 8.22591 6.35759 7.77449 6.63687 7.4952L9.49402 4.63806C9.77259 4.35949 10.2283 4.36163 10.5047 4.63806L13.3612 7.4952Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: "icon-download",
};
</script>
<style scoped lang="scss"></style>
